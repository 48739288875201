<template>
  <div class="no-data-found">
    <IconNoData />
    <h1>No results found</h1>
    <p>Try adjusting your search or filter to find what you’re looking for.</p>
  </div>
</template>

<script>
import IconNoData from "@/components/ui/icons/IconNoData";
export default {
  name: "NoDataFound",
  components: {IconNoData}
}
</script>

<style lang="scss" scoped>
.no-data-found {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #c4d0da;
  padding: 48px 0;

  h1 {
    font-family: Arial;
    font-style: normal;
    font-weight: 550;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    margin: 37px 0 16px 0;
    color: #000000;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #000000;
  }
}
</style>
