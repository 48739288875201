<template>
  <div class="dashboard">
    <Filters
      :loading="loading"
      :on-change="onChangeFilters"
      :use-categories="true"
      :load-on-init="!isAdmin"
      @filterError="onErrorFilters"
    />
    <div v-if="!loading && !noData" class="row row-equal">
      <PerTime :data="timeStats"/>
      <PieTime v-if="!isCategoriesExists" :data="pieStats"/>
      <PieTime v-else :data="pieCategories" :label="'Pie chart by categories'"/>
      <TopProducts :data="visibleList" :progressMax="progressMax"/>
    </div>

    <InfoTiles :data="infoTiles"/>

    <Stats
      v-show="!noData"
      :impressionsCount="impressionsCount"
      :productsCount="productsCount"
      :usersCount="usersCount"/>

    <div v-show="!noData" class="row">
      <Browsers :data="browserStats"/>
      <OS :data="osStats"/>
      <Platforms :data="platformsStats"/>
      <Devices :data="devicesStats"/>
    </div>

    <div v-show="!noData" class="row">
      <RetailerStats :data="retailerStats"/>
      <LicenseStats :data="licenseStats"/>
    </div>

    <Maps v-show="!noData" :countriesData="mapStats" :ipData="IPStats"/>

    <DataStats v-show="!noData" :data="dataStats"/>

    <NoDataFound v-show="noData"/>

    <Portal to="body">
      <the-modal v-if="showAmazonPopup" @close="onCloseModal">
        <div class="the-modal__title">Welcome!</div>
        <div class="the-modal__text">
          You have successfully connected amazon seller account to 24stream.<br>
          Retailer was created!
        </div>
        <div class="the-modal__buttons">
          <button class="the-modal__button the-modal__button--black" @click="onCloseModal">I understand!</button>
        </div>
      </the-modal>
    </Portal>

  </div>
</template>

<script>
/* eslint-disable */
import BubbleMapData from '../../services/data/maps/BubbleMapData';
import ElasticAPI from '../../services/API/ElasticAPI';
import HelperUser from "../../services/Helpers/HelperUser";
import fetchFilters from '../../mixins/fetchFilters'
import {showToastError} from "@/services/Helpers/HelperToast";
import NoDataFound from "@/components/stats/NoDataFound";
import PieTime from '../stats/PieTime'
import {Portal} from "portal-vue";
import TheModal from '@/components/TheModal';

export default {
  name: 'dashboard',
  mixins: [fetchFilters],
  components: {
    NoDataFound,
    Platforms: () => import('../stats/Platforms'),
    Devices: () => import('../stats/Devices'),
    Maps: () => import('../stats/Maps'),
    DataStats: () => import('../stats/DataStats'),
    LicenseStats: () => import('../stats/LicenseStats'),
    RetailerStats: () => import('../stats/RetailerStats'),
    Stats: () => import('../stats/Stats'),
    InfoTiles: () => import('../stats/InfoTiles'),
    OS: () => import('../stats/OS'),
    Browsers: () => import('../stats/Browsers'),
    TopProducts: () => import('../stats/TopProducts'),
    PieTime,
    PerTime: () => import('../stats/PerTime'),
    Filters: () => import('../filters/Filters'),
    Portal,
    TheModal
  },

  data() {
    return {
      productsCount: 0,
      usersCount: 0,
      mapStats: {
        latlong: BubbleMapData.latlong,
        data: [],
      },
      checksStats: {
        datasets: [],
        labels: [],
      },
      IPStats: {
        latlong: {},
        data: [],
      },
      retailerStats: {
        labels: ['all'],
        datasets: [],
      },
      timeStats: {
        labels: ['all'],
        datasets: [],
      },
      licenseStats: {
        labels: ['all'],
        datasets: [],
      },
      pieStats: {
        labels: [],
        datasets: [],
      },
      pieCategories: {
        labels: [],
        datasets: [],
      },
      browserStats: {
        labels: [],
        datasets: [],
      },
      osStats: {
        labels: [],
        datasets: [],
      },
      devicesStats: {
        labels: [],
        datasets: [],
      },
      platformsStats: {
        labels: [],
        datasets: [],
      },
      impressionsCount: 0,
      visibleList: [],
      dataStats: [],
      progressMax: 0,
      emptyFields: 0,
      isCategoriesExists: false,
      noData: false,
      isAdmin: false,
      showAmazonPopup: false,
    };
  },

  created() {
    this.showAmazonPopup = !!localStorage.getItem('showAmazonSignUpModal');
    this.isAdmin = JSON.parse(localStorage.getItem('ttlUserInfo'))?.roles.includes('ROLE_ADMIN')
    this.loading = !this.isAdmin
  },
  methods: {
    async loadData(licenses, retailers, categories) {
      this.loading = true;
      this.noData = false;
      this.infoTiles[1].value = this.filters.licenses.length
      const promiseRetailers = ElasticAPI.getRetailersStats(this.filters);
      const promiseMap = ElasticAPI.getMapStats(this.filters, BubbleMapData);
      const promiseTimeStat = ElasticAPI.getTimeStats(this.filters);
      const promiseLicenseStat = ElasticAPI.getLicenseStats(this.filters);
      const promisePieStat = ElasticAPI.getPieStats(this.filters);
      // const promisePieCategories = ElasticAPI.getPieCategories(this.filters);
      const promisePopularProductsStat = ElasticAPI.getPopularProductsStat(this.filters);
      const promiseDataStats = ElasticAPI.getDataStats(this.filters);
      const promiseDataDevices = ElasticAPI.getDevicesStats(this.filters);
      const promiseIPMap = ElasticAPI.getIPMap(this.filters);
      const promiseEpackages = ElasticAPI.getEpackagesCount(this.filters);
      const promiseProducts = ElasticAPI.getProductsCount(this.filters);
      const promiseUsers = ElasticAPI.getUsersCount(this.filters);
      const promiseMonitor = ElasticAPI.getDataLiveMonitor(licenses.data, retailers.data);

      promiseEpackages.then(data => {
        this.infoTiles[0].value = (data).toLocaleString();
      })
      this.$set(this, 'emptyFields', 0)

      const checkData = (data) => {
        if (data === 0 || data?.length === 0 || data?.[0]?.['data']?.length === 0) {
          this.$set(this, 'emptyFields', this.emptyFields + 1);
        }
      }

      promiseProducts.then(data => {
        this.productsCount = data;
        checkData(data)
      })

      promiseUsers.then(data => {
        this.usersCount = data;
        checkData(data)
      })

      promiseIPMap.then(({latlong, datasets}) => {
        this.IPStats = {
          latlong,
          data: datasets,
        };
        checkData(datasets)
      })

      promiseRetailers.then(data => {
        this.impressionsCount = 0;
        data.forEach(info => {
          this.impressionsCount += info.data.reduce((sum, current) => sum + current);
        });
        this.retailerStats = {
          labels: ['all'],
          datasets: data,
        };
        checkData(data)
      })

      promiseMap.then(data => {
        this.mapStats = {
          latlong: BubbleMapData.latlong,
          data,
        };
        checkData(data)
      })

      promiseTimeStat.then(({labels, datasets}) => {
        this.timeStats = {labels, datasets};
        checkData(datasets)
      })

      promiseLicenseStat.then(data => {
        this.licenseStats = {
          labels: ['all'],
          datasets: data,
        };
        checkData(data)
      })

      promisePieStat.then(({labels, datasets}) => {
        this.pieStats = {labels, datasets};
        checkData(datasets)
      })

      // promisePieCategories.then(({labels, datasets}) => {
      //   this.pieCategories = {labels, datasets};
      //   this.isCategoriesExists = datasets.some(i => !!i.data.length)
      //   checkData(datasets)
      // })

      promisePopularProductsStat.then(({progressMax, datasets}) => {
        this.progressMax = progressMax;
        this.visibleList = datasets;
        this.visibleList.forEach((product, index) => {
          product.isShow = index >= 0 && index < 5;
        });
        checkData(datasets)
      })

      promiseDataDevices.then(({browser, os, device, platforms}) => {
        this.browserStats = {
          labels: browser.labels,
          datasets: browser.datasets,
        };
        this.osStats = {
          labels: os.labels,
          datasets: os.datasets,
        };
        this.devicesStats = {
          labels: device.labels,
          datasets: device.datasets,
        };
        this.platformsStats = {
          labels: platforms.labels,
          datasets: platforms.datasets,
        };
        checkData(browser.datasets)
        checkData(os.datasets)
        checkData(device.datasets)
        checkData(platforms.datasets)
      })
      promiseDataStats.then(data => {
        this.dataStats = data;
        checkData(data)
      })
      promiseMonitor.then((data) => {
        if (data !== 0) {
          const {checks, retailersConnected} = data
          this.checksStats = checks;
          this.$set(this.infoTiles[2], 'value', `${retailersConnected.length} / ${this.filters.retailers.length}`);
        } else {
          this.$set(this.infoTiles[2], 'value', `0 / ${this.filters.retailers.length}`);
        }
      })

      const promiseAll = Promise.allSettled([
        promiseRetailers,
        promiseMap,
        promiseTimeStat,
        promiseLicenseStat,
        promisePieStat,
        // promisePieCategories,
        promisePopularProductsStat,
        promiseDataStats,
        promiseDataDevices,
        promiseIPMap,
        promiseEpackages,
        promiseProducts,
        promiseUsers,
        promiseMonitor,
      ]);

      promiseAll.then(some => {
        this.noData = this.emptyFields >= 13;
        this.loading = false
        const rejected = some.filter(result => result.status === 'rejected').map(result => result.reason)
        if (rejected.length !== 0)
          throw rejected
        this.loading = false;
      }).catch(rejected => {
        this.noData = true;
        console.error('Some api failed');
        console.error(rejected);
        showToastError('Something went wrong', this.$toast)
        this.visibleList = [];
        this.timeStats = {
          labels: ['all'],
            datasets: [],
        };
        this.pieStats = {
          labels: [],
            datasets: [],
        };
        this.infoTiles = [
          {
            color: 'success',
            value: '0',
            text: 'E-packages',
            icon: '',
          },
          {
            color: 'danger',
            value: "0",
            text: 'Licenses',
            icon: '',
          },
          {
            color: 'info',
            value: "0",
            text: 'Retailers connected',
            icon: '',
          },
        ];
        this.loading = false;
      });
      return promiseAll;
    },

    async refreshData() {
      const {licenses, retailers} = HelperUser.getUserLicensesRetailers(
        this.filters.licenses,
        this.filters.retailers
      );
      this.defaultFilters["range"] = this.filters.range;
      this.defaultFilters["contentType"] = this.filters.contentType;
      return this.loadData(licenses, retailers, this.filters.categories);
    },

    onCloseModal() {
      localStorage.removeItem('showAmazonSignUpModal')
      this.showAmazonPopup = false
    }
  },

};
</script>

<style lang="scss">
.row-equal .flex {
  .vac-card {
    height: 100%;
  }
}

.dashboard {
  .vac-card {
    margin-bottom: 0 !important;
  }
}

.link-icon {
  color: #000000;
  transition: 0.3s;
  cursor: pointer;
}

.link-icon:hover {
  opacity: 0.7;
}

.td--small {
  width: 20%;
}

.dashboard-contributors-list {
  flex-direction: column;
  height: 100%;
}

.inner-loading {
  table {
    width: 100%;
  }

  .va-table td {
    word-break: break-all;
  }

  tbody,
  thead {
    width: 100%;
  }
}

</style>
