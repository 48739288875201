<template>
  <svg width="166" height="166" viewBox="0 0 166 166" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="83" cy="83" r="83" fill="#E3E9ED"/>
    <rect x="89" y="13" width="64" height="64" rx="6" fill="white"/>
    <path d="M103 52L109 41L117 47L124 37L131 43L139 34" stroke="#DB458D" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M103 43L117 57L124 47L131 51.75H139" stroke="#5145DB" stroke-width="1.5" stroke-linecap="round"/>
    <rect x="13" y="89" width="64" height="64" rx="6" fill="white"/>
    <path d="M45 103C47.3638 103 49.7044 103.466 51.8883 104.37C54.0722 105.275 56.0565 106.601 57.7279 108.272C59.3994 109.944 60.7252 111.928 61.6298 114.112C62.5344 116.296 63 118.636 63 121L54.888 121C54.888 119.701 54.6322 118.416 54.1353 117.216C53.6384 116.016 52.91 114.926 51.9919 114.008C51.0737 113.09 49.9836 112.362 48.784 111.865C47.5843 111.368 46.2985 111.112 45 111.112V103Z" fill="#ABDB45"/>
    <path d="M62.9281 122.607C62.6177 126.069 61.3115 129.368 59.1673 132.104C57.0231 134.839 54.1326 136.896 50.8449 138.025C47.5573 139.153 44.0132 139.306 40.6407 138.464C37.2681 137.622 34.2115 135.822 31.84 133.281L37.7708 127.746C39.0735 129.142 40.7526 130.131 42.6053 130.594C44.4579 131.056 46.4048 130.972 48.2108 130.352C50.0168 129.732 51.6047 128.602 52.7825 127.1C53.9604 125.597 54.678 123.785 54.8485 121.883L62.9281 122.607Z" fill="#45DBB7"/>
    <path d="M30.5226 131.696C28.6201 129.121 27.4389 126.085 27.1007 122.901C26.7625 119.718 27.2796 116.501 28.5987 113.584C29.9177 110.667 31.991 108.154 34.6048 106.305C37.2185 104.456 40.2781 103.338 43.4681 103.065L44.1585 111.148C42.4061 111.298 40.7254 111.912 39.2896 112.928C37.8537 113.943 36.7148 115.324 35.9902 116.926C35.2656 118.529 34.9816 120.296 35.1673 122.044C35.3531 123.793 36.002 125.461 37.0471 126.876L30.5226 131.696Z" fill="#45DB54"/>
    <rect x="13" y="13" width="64" height="64" rx="6" fill="white"/>
    <rect x="27" y="30" width="36" height="6" rx="1" fill="#A245DB"/>
    <rect x="27" y="46" width="22" height="6" rx="1" fill="#4598DB"/>
    <rect x="27" y="38" width="30" height="6" rx="1" fill="#45C9DB"/>
    <rect x="27" y="54" width="9" height="6" rx="1" fill="#F3972B"/>
    <rect x="89" y="89" width="64" height="64" rx="6" fill="white"/>
    <rect x="103" y="106" width="6" height="6" rx="1" fill="#8D9CA8"/>
    <rect x="103" y="122" width="6" height="6" rx="1" fill="#8D9CA8"/>
    <rect x="111" y="106" width="28" height="6" rx="1" fill="#8D9CA8"/>
    <rect x="111" y="122" width="28" height="6" rx="1" fill="#8D9CA8"/>
    <rect x="103" y="114" width="6" height="6" rx="1" fill="#C4D0DA"/>
    <rect x="103" y="130" width="6" height="6" rx="1" fill="#C4D0DA"/>
    <rect x="111" y="114" width="28" height="6" rx="1" fill="#C4D0DA"/>
    <rect x="111" y="130" width="28" height="6" rx="1" fill="#C4D0DA"/>
  </svg>

</template>

<script>
export default {
  name: "IconNoData"
}
</script>
